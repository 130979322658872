@import url('https://fonts.googleapis.com/css2?family=Geist:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Geist', sans-serif;
}

@layer components {
    .cabecalho-a {
        @apply no-underline text-white transition-all duration-500 hover:text-stone-200;
    }

    .botao-a {
        @apply w-full rounded bg-primary hover:bg-dark text-white text-center py-2 mt-4 text-sm transition duration-1000;
    }
}
